<!--
 * @Descripttion: 首页
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-06 13:51:43
-->

<template>
  <div class="common-layout">
    <wk-header :currentIndex="1"></wk-header>
    <el-main>
      <el-row>
        <img src="./../assets/img/banner.webp" class="wk-banner">
        <div class="wk-banner-title">
          <div>
            <h2 style="font-size: 4.5em">嗨微客</h2>
            <h2 class="title_desc">
              <span>行业工具一网扫尽</span>
              <span>行业资讯一览无遗</span>
            </h2>
            <!-- <h3>嘟聪嗨微客您创业的好助手</h3> -->
          </div>
        </div>
      </el-row>
      <!-- 首页内容 -->


      <el-container>
        <el-row class="w100">
          <el-col :span="18" :offset="3" class="mt30" @mouseleave="handleMouseLeave" style="position:relative">
            <el-row>
              <el-col
                :class="{'home-tool': true, blue: firstIndex === index}"
                :span="8"
                v-for="(item,index) in labelTree"
                :key="item.id"
                @mouseover="handleMouseOver(item.id,item.children,index)"
              >
                <img :src="item.labelIconUrl">
                <p>{{ item.labelName }}</p>
              </el-col>
            </el-row>
            <el-row :class="{ 'ab-row': true, 'show': firstIndex !== ''} ">
              <ul class="label-type">
                <li :class="secondIndex === '' ? 'active':''" @click="handleToolList('')">全部</li>
                <li v-for="(type,index) in currentTypeList" :class="{active: secondIndex === index}"  :key="type.id" @click="handleToolList(type,index)">{{ type.labelName }}</li>
              </ul>
              <ul class="home-tool-ul">
                <li v-for="tool in currentToolList" :key="tool.id" @click="handleToolDetail(tool)">
                  <img :src="tool.propagatePic">
                  <div>
                    <h3 class="el-ellipsis">{{ tool.toolName }}</h3>
                    <p class="tool-description el-ellipsis">{{ tool.description }}</p>
                  </div>
                </li>
              </ul>
              <p class="tool-more" v-if="currentToolList.length >=12" ><span @click="showMoreTools">查看更多工具>></span></p>
            <div class="el-loading-mask" :class="{loading :showLoading}"><div class="el-loading-spinner"><svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none"></circle></svg><!----></div></div>
            </el-row>
          </el-col>
        </el-row>
      </el-container>

      <el-container class="tool">
        <el-row class="w100" :gutter="20">
          <el-col :span="18" :offset="3">
            <el-row :gutter="50">
              <el-col :span="12" v-for="item in newsData" :key="item">
                <div class="tool-information" @click="handleNewsDetail(item)">
                  <img :src="item.mainPic" alt="">
                  <div>
                    <p class="tool-information-title el-ellipsis--l2">{{item.title}}</p>
                    <p class="tool-information-industry">{{item.industryLabel}}</p>
                    <div class="tool-information-count">
                      <p>{{item.readNums}}人浏览</p>
                      <p v-if="item.duration<60">{{item.duration}}分钟之前发布</p>
                      <p v-else-if="item.duration>=60&&item.duration<1400">{{parseInt(item.duration/60)}}小时之前发布</p>
                      <p v-else-if="item.duration>=1400&&item.duration<2880">24小时之前发布</p>
                      <p v-else-if="item.duration>=2880&&item.duration<4320">48小时之前发布</p>
                      <p v-else>{{item.publishTime}}</p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

      </el-container>
      <div class="tool-information-footer" @click="handleNewsMore">
        查看更多>>
      </div>
      <el-container class="tool">
        <el-row :gutter="20" class="w100">
        <el-col :span="18" :offset="3">
          <el-row :gutter="20">
            <el-col :span="4" style="text-align: center; padding:20px" v-for="item in partners" :key="item" >
              <img :src="item.partnerPic" alt="" style="height:60px">
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      </el-container>
    </el-main>
    <wk-footer></wk-footer>
  </div>
</template>
<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {getAction, postAction} from './../utils/http'
import axios from  'axios'

export default {
  name: 'Index',
  components: {
    WkHeader,
    WkFooter,
  },

  data() {
    return {
      url: {
        getTools: '/wbpt/tools/tbWbptTools/getToolsCollection',
				getNewsCollection:'/wbpt/tools/tbWbptTools/getNewsCollection',
        getPartners:'/wbpt/partners/tbWbptPartners/list',
        labelTree: '/wbpt/label/tbWbptLableManage/getLabelTree',
        firstTools: '/wbpt/tools/home/getToolsCollection',
        updateIndexReadNums: '/wbpt/statistics/tbWbptIndexTrace/updateIndexReadNums'
      },
			imgUrl:"https://hiweke.bidsoon.cn/sys/common/static/",
			//工具分类
			labelSorts:[],
			//资讯
			newsData:[],
      partners:[],
      labelTree: [],
      firstIndex:'',
      secondIndex: '',
      currentTypeList: [],
      currentToolList:[],
      showLoading: false
    }
  },

	mounted(){
    this.initLabelList()
		//工具集合
		// this.getTools()
		//资讯集合
		this.getNewsCollection();
    //获取合作伙伴
    this.getPartners();
    //首页浏览数+1
    this.addIndexReadnum();
	},
  methods: {

    /**
     * @Description: 查询标签
     * @Author: Niklaus
     * @Date: 2021-12-17 18:01:19
     */
    initLabelList(){
      getAction(this.url.labelTree,{labelCode: '02'}).then( res => {
        if(res.success) {
          this.labelTree =  res.result.treeData
          /** 查询到类别后，查询 主类别下的 工具列表 */
          this.labelTree.forEach(item => this.initFirstLabelTool(item.id))
        }
      })
    },

    /**
     * @Description: 根据主标签查询相关联的工具
     * @Author: Niklaus
     * @Date: 2021-12-17 18:01:34
     * @param {*} industryId : 行业ID
     * @param {*} isUpdate : 是否更新工具列表数据
     */
    initFirstLabelTool(industryId, sortId='', isUpdate = false) {
      let data = {
        industryId,
        sortId,
        page: 1,
        pageSize: 12,
        newFlag: '1',
        fromRoute: 'home'
      }
      this.showLoading = true
      postAction(this.url.firstTools, data).then(res =>{
        if(res.retCode == 0) {
          if(this.firstIndex =='' && this.secondIndex =='') {
            this.$ls.set(industryId, res.rows, 8 * 60 * 60 * 1000)
          }
          if(isUpdate)
            this.currentToolList = res.rows
        }else {
          console.log('查询工具标签错误');
        }
        this.showLoading = false
      })
    },

    /**
     * @Description: 鼠标移入事件
     * @Author: Niklaus
     * @Date: 2021-12-17 18:02:51
     * @param {*} id
     * @param {*} list
     * @param {*} index
     */
    handleMouseOver(id,list,index) {
      this.firstIndex  =index
      this.secondIndex = ''
      this.currentTypeList = list
      if(this.$ls.get(id))
        this.currentToolList =  this.$ls.get(id)
      else
        this.initFirstLabelTool(id, true)
    },

    /**
     * @Description: 鼠标移出事件
     * @Author: Niklaus
     * @Date: 2021-12-17 18:03:09
     */
    handleMouseLeave() {
      this.firstIndex = ''
    },

    /**
     * @Description: 根据标签查询工具
     * @Author: Niklaus
     * @Date: 2021-12-17 18:03:45
     */
    handleToolList(item,index) {
      /** 如果 item == '' 则表示查询全部  */
      if(item === '') {
        this.secondIndex = ''
        this.currentToolList = this.$ls.get(this.labelTree[this.firstIndex].id)
      }else {
        this.secondIndex = index
        this.initFirstLabelTool(this.labelTree[this.firstIndex].id, item.id, true)
      }

    },
		//查询资讯集合
		getNewsCollection:function(){
			this.newsData = []
			getAction(this.url.getNewsCollection).then(res =>{
				if(res.rows){
					this.newsData = res.rows
					for(let ii=0;ii<this.newsData.length;ii++){
						if(this.newsData[ii].industrys){
							let industryLabel = ''
							for(let jj=0;jj<this.newsData[ii].industrys.length;jj++){
								industryLabel += this.newsData[ii].industrys[jj].labelName + ' '
							}
							this.newsData[ii].industryLabel = industryLabel.substr(0,industryLabel.length - 1)
						}
					}
				}
			})
		},
    handleToolDetail(item){
      this.$router.push({
        path: '/toolDetail',
        query: {
          toolId: item.id
        }
      })
    },
    //点击查看详情
    handleNewsDetail(item) {
      this.addNewsReadnum(item.id)
      if(item.newsUrlPc){
        window.open(item.newsUrlPc,'_blank');
      }else{
        this.$router.push({
          path: '/newsDetail',
          query: {
            newsId: item.id
          }
        })
      }
    },
    //资讯-阅读数加1
		addNewsReadnum:function(id){
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let ip = "";
      if(sessionStorage.getItem('ip')){
        ip = sessionStorage.getItem('ip')
      }
      let obj={
        newsId:id,
        userId:userId,
        ip:ip
      }
      getAction("/wbpt/news/home/updateNewsReadnumWithId",obj).then(res=>{
        console.log(res)
      })     
		},
    //首页-阅读数加1
		addIndexReadnum:function(){
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let ip = "";
      let cid = "";
      let cname = "";
      if(sessionStorage.getItem('ip')){
        ip = sessionStorage.getItem('ip')
      }
      if(sessionStorage.getItem('cid')){
        cid = sessionStorage.getItem('cid')
      }
      if(sessionStorage.getItem('cname')){
        cname = sessionStorage.getItem('cname')
      }
      let obj={
        userId:userId,
        ip:ip,
        cid:cid,
        cname:cname
      }
      getAction(this.url.updateIndexReadNums,obj).then(res=>{
        console.log(res)
      })     
		},    
    //获取合作伙伴
    getPartners(){
      let params ={
        pageNo:1,
        pageSize:12,
        enabledStatus:'1'
      }
      getAction(this.url.getPartners,params).then(res=>{
        if(res.success){
          this.partners = res.result.records;
        }else{
          // ElMessage.error('获取合作伙伴失败！')
           console.log('获取合作伙伴失败！');
        }
      }).catch(err=>{
          // ElMessage.error('获取合作伙伴出错!')
          console.log(err);
      });
    },

    //查看资讯更多
    handleNewsMore(){
      this.$router.push('/news')
    },

   /**
    * @Description: 查看更多工具
    * @Author: Niklaus
    * @Date: 2021-12-20 10:12:40
    */
    showMoreTools() {
      let temp1 = this.labelTree[this.firstIndex]?.id?? '',
          temp2 = this.secondIndex ? this.currentTypeList[this.secondIndex].id : ''
      this.$router.push({
        path: '/tools',
        query: {
          industryId: temp1,
          sortId: temp2
        }
      })
    }
  }
}
</script>

<style scoped>

.el-main {
  padding-left: 0;
  padding-right: 0;
}
.wk-banner {
  width: 100%;
  position: relative;
}
.wk-banner-title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.wk-banner-title h1 {
  font-size: 5em;
}
.wk-banner-title h3 {
  font-size: 2.5em;
  margin-top: 0.5em;
  font-weight: normal;
}
.tool {
  margin-top: 80px;
}
.tttt {
  font-size: 14px;
  margin-top: 12px;
}
.tool-li {
  cursor: pointer;
  padding-bottom: 1rem;
}
.tool-more {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.tool-more >span {
  display: inline-block;
  padding: 10px 25px;
  color: #737373;
  background-color: #fff;
}
.el-loading-mask {
  display: none;
}
.loading {
  display: block;
}
.title_desc {
  font-size: 3.5em;
}
.title_desc span {
  display: inline-block;
}
.title_desc span:last-child {
  margin-left: 1em;
}

</style>
